<template>

  <div class="ui__rating"> 
 
    <i 
      v-if="showStars"
      v-for="(opt,opt_key) in stars"
      :key="opt_key"
      :class="'ui__star ' + (vl >= opt ? 'icon-star' : 'icon-star-empty')"
      @click="choose(opt)"
    >
    </i>

    <i 
      v-else="showStars"
      :class="'ui__star ' + 'icon-star'"
    >
    </i>

    <div 
      v-if="showRate"
      class="rate"
    >
      <span class="v">{{ vl }}</span><span class="o">/{{ of }}</span>
    </div>
    
        
  </div> 

</template>

<script setup lang="ts">

const emits = defineEmits(['change'])

interface Props {
  cnt: number
  vl: number | null
  editable?: boolean
  showRate?: boolean
  showStars?: boolean
  of?: number
}

const props = withDefaults(defineProps<Props>(), {
  cnt: 5,
  vl: 0,
  editable: false,
  showRate: false,
  showStars: true,
  of: 10
})

const stars = computed(() => [...Array(props.cnt).keys()].map(i => i + 1))

const choose = (opt) => {
  if(props.editable){
    emits('change', opt)
  }
}

</script>

<style scoped>
 
 .ui__rating{
  display: flex;
  align-items: center;
  gap: 5px;
 }
 
 .ui__star{
  /* width: 15px;
  height: 15px; */
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  /* margin-right: 3px; */
 }
 /* .star.full{
   background-image: url(/images/rating/star_full.svg);
 }
 .star.empty{
   background-image: url(/images/rating/star_empty.svg);
 } */

.icon-star {
  color: #f1c40f;
}
.icon-star-empty {
  color: gray;
}

.rate{

}

.rate .v{
font-weight: bold;
}

.rate .o{
  /* color: var(--un-text-color-gray); */
}


</style>
